// Disabling for the whole file since these are utils wrapped in a class but
// eslint thinks this is a class React component
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';

import { featureFlagOverridesKey } from './local-storage-keys';

export enum FeatureFlag {
  IPAccessList = 'ip_access_list',
  PushPullCodeDeploy = 'push_pull_code_deploy',
  ShowPBSConfig = 'show_pbs_config',
  EnabledRevampedTriggersUI = 'enable_revamped_triggers_ui',
  EnableTypeScriptInFunctions = 'enable_typescript_in_functions',
  FilterTriggersByName = 'filter_triggers_by_name',
  // TODO (BAAS-31888): Remove this feature flag
  GraphQLAllowed = 'graphql_allowed',
  // TODO (BAAS-31889): Remove this feature flag
  HostingAllowed = 'hosting_allowed',
  SyncAllowed = 'sync_allowed',
  SyncEOL = 'sync_eol',
  // TODO (BAAS-33823): Remove this feature flag
  DataAPIEndpointsAllowed = 'DATA_API_ENDPOINTS_ALLOWED',

  // atlas ff
  BillingMigration = 'BAAS_BILLING_MIGRATION',
  PricingChange = 'BAAS_PRICING_CHANGE',
  // TODO (BAAS-31889): Remove this feature flag
  BaasHostingDeprecated = 'BAAS_HOSTING_DEPRECATED',
  // TODO (BAAS-31888): Remove this feature flag
  BaasGraphQLDeprecated = 'BAAS_GRAPHQL_DEPRECATED',
  // TODO (BAAS-33823): Remove this feature flag
  DeprecateEndpointsAndDataAPI = 'DEPRECATE_ENDPOINTS_AND_DATA_API',
}

export enum EmptyStateModalVariant {}

export const abTestVariantsByTestName = {};

export interface Features {
  [key: string]: boolean | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ListenerFunctions = Set<() => void>;

export default class FeatureSettings {
  // baasFeatureFlags are features that are set on features.enabled on the app settings document that we fetch
  private baasFeatureFlags: Features;

  // atlasFeatureFlags are features that are set on features.enabled on the app settings document that we fetch
  private atlasFeatureFlags: Features;

  // featureOverrides are any flags that are set in localstorage with the baas_ui_feature_overrides key
  private featureOverrides: Features;

  private featureSettingsListeners: ListenerFunctions;

  constructor(baasFlags?: Features, atlasFlags?: Features) {
    this.baasFeatureFlags = { ...baasFlags };
    this.atlasFeatureFlags = { ...atlasFlags };
    this.featureOverrides = {};
    this.featureSettingsListeners = new Set();
  }

  // TODO(BAAS-19343): Remove defaultValue param
  get(flag: FeatureFlag): boolean {
    const overrideValue = this.featureOverrides[flag];
    if (overrideValue !== undefined) {
      return overrideValue;
    }

    const baasFFVal = this.baasFeatureFlags[flag] ?? false;
    const atlasFFVal = this.atlasFeatureFlags[flag] ?? false;

    if (!baasFFVal && !atlasFFVal) {
      return false;
    }

    return baasFFVal || atlasFFVal;
  }

  setAtlasFeatures(features: string[]) {
    features.forEach((feature) => {
      this.atlasFeatureFlags[feature] = true;
    });
    this.featureSettingsListeners.forEach((listener) => listener());
  }

  // useFeatureSetting is a custom hook that returns whether the current user
  // has a feature flag set. As a hook, this can only be used in a functional component.
  // TODO(BAAS-19343): Remove defaultValue param
  useFeatureSetting(flag: FeatureFlag) {
    // Hooks are ok to be used here as eslint thinks this is a class component

    const [setting, setSetting] = useState(this.get(flag));
    const checkFeatureSetting = () => setSetting(this.get(flag));

    // listen to changes to feature settings
    React.useEffect(() => {
      this.featureSettingsListeners.add(checkFeatureSetting);
      return () => {
        this.featureSettingsListeners.delete(checkFeatureSetting);
      };
    }, []);

    return setting;
  }

  // setAppFeatures populates featureFlags and featureOverrides
  setAppFeatures(appID: string, enabledFeatureFlags: FeatureFlag[]) {
    if (enabledFeatureFlags.length > 0) {
      enabledFeatureFlags.forEach((feature) => {
        this.baasFeatureFlags[feature] = true;
      });
    }

    try {
      const featureFlagOverrides = localStorage.getItem(featureFlagOverridesKey(appID)) || '[]';
      this.featureOverrides = JSON.parse(featureFlagOverrides);
    } catch {
      this.featureOverrides = {};
    }
  }

  clearAppFeatures() {
    this.baasFeatureFlags = {};
    this.featureOverrides = {};
  }

  clearAtlasFeatures() {
    this.atlasFeatureFlags = {};
  }
}
